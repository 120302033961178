import { Layout, SignIn } from 'components';
import type { NextPage, NextPageContext } from 'next';
import { csrfToken as getToken } from 'next-auth/client';
import React from 'react';

const Login: NextPage<{ csrfToken: string | null }> = ({ csrfToken }) => {
  return (
    <Layout title="S&F Financial App">
      <SignIn token={`${csrfToken}`} />
    </Layout>
  );
};

Login.getInitialProps = async (context: NextPageContext): Promise<{ csrfToken: string | null }> => {
  return {
    csrfToken: await getToken(context),
  };
};

export default Login;
